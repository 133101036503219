import React, { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { get } from "../utils/get";
import Sidebar from "./SideBar";

export default function TrackingList() {
   const dum = [1, 2, 3, 4, 5];
   const [trackingList, setTrackingList] = useState([
      { tracking_id: "...", destination: "...", pickup: "..." },
   ]);
   useEffect(() => {
      getTrackings();
   }, []);

   // get all trackings
   const getTrackings = async () => {
      try {
         const response = await get("/tracking-list/all");
         console.log(response);
         setTrackingList(response.data);
      } catch (error) {
         console.log(error);
      }
   };

   const deleteTracking = async (_id) => {
      try {
         const response = await get(`/tracking-list/delete?_id=${_id}`);
         console.log(response);
         getTrackings();
      } catch (error) {
         console.log(error);
      }
   };
   return (
      <Sidebar>
         <div className="tracking-list py-5">
            <div className="container">
               <div className="head py-3">
                  <span className="fs-4 fw-bold">Tracking List</span>
               </div>
            </div>
            <div className="container table-responsive">
               <table width={"100%"} className="shadow">
                  <thead>
                     <tr className="bg-danger text-light">
                        <th>Tracking ID</th>
                        <th>Pickup Location</th>
                        <th>Destination Location</th>
                        <th>Date</th>
                        <th className="text-center">Action</th>
                     </tr>
                  </thead>
                  <tbody>
                     {trackingList.map((tracking, index) => (
                        <tr key={index}>
                           <td>
                              <Link
                                 to={`/tracking/id?tracking_id=${tracking.tracking_id}`}
                              >
                                 {tracking.tracking_id}
                              </Link>
                           </td>
                           <td>{tracking.pickup}</td>
                           <td>{tracking.destination}</td>

                           <td>{tracking.date}</td>
                           <td className="">
                              <button
                                 className="btn btn-danger mx-auto d-flex align-items-center gap-2"
                                 onClick={() => deleteTracking(tracking._id)}
                              >
                                 {" "}
                                 <FaTrash /> Delete{" "}
                              </button>
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            </div>
         </div>
      </Sidebar>
   );
}
