/**
 * path includes message, locaton, and time
 */
export const pathInitialState = {
	country: "",
	path: [],
};

//Actions
const ADD_COUNTRY = "ADD_COUNTRY";
const ADD_PATH = "ADD_PATH";
const CLEAR = "CLEAR";

// Action Creator
export const addCountry = (country) => ({
	type: ADD_COUNTRY,
	payload: country,
});

export const addPath = (path) => ({
	type: ADD_PATH,
	payload: path,
});

export const CLEARR = () => ({
	type: CLEAR,
});

const pathReducer = (state = pathInitialState, action) => {
	switch (action.type) {
		case ADD_COUNTRY:
			return { ...state, country: action.payload };

		case ADD_PATH:
			return { ...state, path: [...state.path, action.payload] };

		case CLEAR: {
			return { ...pathInitialState };
		}
		default:
			break;
	}
};

export default pathReducer;
