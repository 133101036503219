const initialState = {
	pickup: "",
	destination: "",
	route: [],
};

// Actions
const UPDATE_PICKUP = "UPDATE_PICKUP";
const UPDATE_DESTINATION = "UPDATE_DESTINATION";
const ADD_PATH = "ADD_PATH";

// Action Creator
export const updateRoutePath = (path) => ({
	type: ADD_PATH,
	payload: path,
});

export const updatePickup = (pickup) => ({
    type: UPDATE_PICKUP,
    payload: pickup,
  });
  
  export const updateDestination = (destination) => ({
    type: UPDATE_DESTINATION,
    payload: destination,
  });
  

// REDUCER
const createRouteReducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_PICKUP:
			return {
				...state,
				pickup: action.payload,
			};
		case UPDATE_DESTINATION:
			return {
				...state,
				destination: action.payload,
			};
		case ADD_PATH:
			return {
				...state,
				route: [...state.route, action.payload],
			};
		default:
			return state;
	}
};

export default createRouteReducer;
