import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { get } from "../utils/get";

function TrackingDetails() {
   const location = useLocation();
   const queryParams = new URLSearchParams(location.search);
   const tracking_id = queryParams.get("tracking_id");

   const [trackingData, setTrackingData] = useState("");
   const [err, setErr] = useState(false);
   const navi = useNavigate();

   useEffect(() => {
      getTracking();
   }, []);

   const getTracking = async () => {
      if (!tracking_id) {
         setErr(true);
         return alert("Tracking not found");
         //  return navi("/home");
      }
      try {
         const response = await get(
            `/tracking-list/id?tracking_id=${tracking_id}`
         );
         console.log(response.data);
         setTrackingData(response.data);
      } catch (error) {
         setErr(true);
         console.log(error);
      }
   };
   return (
      <div className="trk-det py-4">
         <div className="container">
            <div className="grid-box col-lg-6 mx-auto shadow rounded">
               <div className="g-head p-3 text-uppercase text-center pb-2">
                  <h1>Tracking Details</h1>
               </div>
               {trackingData === "" ? (
                  err ? (
                     <div className="alert alert-danger m-3 text-center fs-3">
                        No Tracking Found
                        <div className="btn-wrap mt-4">
                           <button
                              className="btn btn-danger btn-sm"
                              onClick={() => navi("/")}
                           >
                              Go Home
                           </button>
                        </div>
                     </div>
                  ) : (
                     <div className="alert alert-info m-3 text-center">
                        Loading Tracking Details...
                     </div>
                  )
               ) : (
                  <div className="g-body">
                     {/* From Container */}
                     <div className="from p-3 py-2">
                        <div className="head">
                           <span className="fs-4 fw-bold">FROM</span>
                        </div>
                        <ul className="m-0">
                           <li>{trackingData.sender_name}</li>
                           <li>{trackingData.sender_address}</li>
                           <li>+{trackingData.sender_phoneNo}</li>
                           <li>{trackingData.sender_email}</li>
                           <li>{trackingData.pickup}</li>
                        </ul>
                     </div>
                     {/* To Container */}
                     <div className="to p-3 py-2">
                        <div className="head">
                           <span className="fs-4 fw-bold">TO</span>
                        </div>
                        <ul className="m-0">
                        <li>{trackingData.reciever_name}</li>
                           <li>{trackingData.reciever_address}</li>
                           <li>+{trackingData.reciever_phoneNo}</li>
                           <li>{trackingData.reciever_email}</li>
                           <li>{trackingData.destination}</li>
                        </ul>
                     </div>
                     {/* Tracking Box */}
                     <div className="p-3 tracking-box fs-5 py-4">
                        <span className="fw-bold">TRACKING NUMBER:</span>
                        <span> {tracking_id}</span>
                     </div>
                     {/* Date Box */}
                     <div className="p-3 date-box">
                        <div className="text-wrap">
                           <span className="fw-bold fs-5"> Date:</span>
                           <span>{trackingData.date}</span>
                        </div>
                        <br />
                        <div className="text-wrap">
                           <span className="fw-bold fs-5"> REF:</span>
                           <span>SPAQ4</span>
                        </div>
                        <br />
                        <div className="text-wrap fw-bold">
                           www.Sperckz.com
                        </div>
                     </div>
                  </div>
               )}
            </div>
         </div>
      </div>
   );
}

export default TrackingDetails;
