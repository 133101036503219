import React from "react";
import { FaMapPin, FaPhone, FaTelegram } from "react-icons/fa";
import Container from "./Container";
import { useNavigate, useLocation } from 'react-router-dom';

export default function Footer() {
   const dum = [1, 1, 1];
   const navigate = useNavigate();
   const location = useLocation();

   const handleClick = () => {
      if (location.pathname !== '/') {
         navigate('/');
      } else {
         window.scrollTo(0, 0);
      }
   };
   return (
      <div className="footer bg-neutral-950 text-gray-300">
         <Container>
            <div className="lg:flex py-10">
               <div className="abt-section col-lg-4 text-white p-2 mb-5 lg:mb-0">
                  <div className="logo-wrap p-4 flex justify-center">
                     <img src="/ficon.png" alt="Sperckz" />
                  </div>
                  <div className="text-wrap text-center font-thin text-sm">
                     <span>
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                        Quia, dignissimos suscipit. Quae maiores iusto porro rerum,
                     </span>
                  </div>
                  <div className="btn-wrap flex justify-center py-3">
                     <button
                        className="p-2 bg-red-500 text-white font-thin"
                        onClick={handleClick}
                     >
                        Track Website
                     </button>
                  </div>
               </div>

               {/* Box section */}
               <div className="grid gap-5 grid-cols-1 lg:grid-cols-3 w-[100%]">
                  <div className="box px-3">
                     <div className="heading border-b-2 border-white w-50">
                        <span className="font-bold text-lg">Newsletter</span>
                     </div>
                     <div className="text-wrap">
                        <br />
                        <div className="span">Subscribe our newsletter to get our latest update & news</div> <br />
                        <div className="form-wrap flex">
                           <input type="text" className="form-control" />
                           <button className="p-1 px-3 bg-red-500 text-white font-thin"><FaTelegram /> </button>
                        </div>
                     </div>
                  </div>

                  <div className="box px-3">
                     <div className="heading border-b-2 border-white w-50">
                        <span className="font-bold text-lg">Official info</span>
                     </div>
                     <div className="text-wrap text-gray-400">
                        <br />
                        <div className="social-icon">
                           <div className="flex items-center mb-3">
                              <FaMapPin className=" text-blue-600 mr-4" size={20} />
                              <div className="tw">
                                 <span>22 Baker Street, London, United Kingdom, W1U 3BW</span>
                              </div>
                           </div>

                           <div className="flex items-center">
                              <FaPhone className=" text-blue-600 mr-4" size={20} />
                              <div className="tw">
                                 <span>22 Baker Street, London, United Kingdom, W1U 3BW</span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="box px-3">
                     <div className="heading border-b-2 border-white w-50">
                        <span className="font-bold text-lg">Quick Link</span>
                     </div>
                     <div className="text-wrap text-gray-400">
                        <br />
                        <ul className="list-disc list-inside">
                           <li>Home </li>
                           <li>About </li>
                           <li>Contact</li>
                           <li><button className="bg-red-500 text-white font-thin px-1" onClick={handleClick}>Track Package</button></li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </Container>
         <div className="caption text-center bg-[#000] text-gray-400 text-lg p-3 font-thin flex justify-between">
            <span>
               All right reserved{" "}
               <span className="text-red-500 font-semibold">
                  Sperckz Logistics
               </span>{" "}
            </span>
            <span>Copyright © 2024</span>
         </div>
      </div>
   );
}
