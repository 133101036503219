import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { get } from "../utils/get";
import { post } from "../utils/postCaller";
import RouteSkeleton from "./dashboard/components/route-skeleton";
import Translator from "./dashboard/components/Translator";
import Footer from "./Frontend/Components/Footer";
import StickyHeader from "./Frontend/Components/Header";
import { TiArrowLeftOutline, TiArrowRightOutline } from "react-icons/ti";
import GoogleTranslate from "./Frontend/Components/GoogleTranslate";

function TrackingShipment() {
   const location = useLocation();
   const queryParams = new URLSearchParams(location.search);
   const tracking_id = queryParams.get("tracking_id");

   const navi = useNavigate();

   const [route, setRoute] = useState({
      pickup: "...",
      destination: "...",
      date: "...",
   });
   const [routeData, setRouteData] = useState({ route: [] });
   const [err, setErr] = useState(false);

   const [recentCountry, setRecentCountry] = useState('')
   const [recentMessage, setRecentMessage] = useState('')
   const [city, setCity] = useState('')

   useEffect(() => {
      getTracking();
   }, []);
   useEffect(() => {
      getRoute();
   }, [route]);
   const getTracking = async () => {
      if (!tracking_id) {
         return navi('/')
      }
      try {
         const response = await get(
            `/tracking-list/id?tracking_id=${tracking_id}`
         );
         setRoute({
            pickup: response.data.pickup,
            destination: response.data.destination,
            date: response.data.date,
         });
         console.log(response);
      } catch (error) {
         console.log(error);
         setErr(true);
      }
   };

   const getRoute = async () => {
      if (route.pickup === "..." || route.destination === "...") {
         return false;
      }
      try {
         setRouteData({ route: [] });
         const response = await get(
            `/tracking-list/auto-track?tracking_id=${tracking_id}`
         );
         console.log(response);
         setRouteData(response.data);
         const { country, path } = getRecentRoute(response.data.route)
         setRecentCountry(country)
         const { message, location } = getRecentStats(path)
         setRecentMessage(message)
         setCity(location)
      } catch (error) {
         console.log(error);
      }
   };

   const getRecentRoute = (route) => {
      const routeLength = route.length
      const index = routeLength - 1
      const recentRoute = route[index]

      console.log(recentRoute)

      const { country, path } = recentRoute
      return { country, path }
   }
   const getRecentStats = (path) => {
      const pathLength = path.length
      const index = pathLength - 1
      const recentPath = path[index]

      const { message, location } = recentPath
      const cityName = location.split(",")[0].trim();
      return { message, location: cityName }
   }

   // console.log(`https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=${route.pickup === "..." ? "Belgium" : route.pickup}&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed`)

   return (
      <div className="bg-gray-100 pt-20">
         <div className="container min-h-screen relative">
            <StickyHeader />
            {/* <Translator /> */}
            <div className="header text-center py-4">
               <span className="fs-3 fw-bold">Track Shipment</span>
            </div>

            <div className="lg:absolute top-[100px]">
               <GoogleTranslate />
            </div>

            <div className="wrap col-lg-6 mx-auto">
               {err ? (
                  <div className="alert alert-danger m-3 text-center fs-3">
                     No Tracking Found
                     <div className="btn-wrap mt-4">
                        <button
                           className="btn btn-danger btn-sm"
                           onClick={() => navi("/tracking/search")}
                        >
                           Find a Tracking
                        </button>
                     </div>
                  </div>
               ) : (
                  <div className="wrap">
                     <Map query={"Belgium"} />
                     {/* Delivery Address */}
                     <div className="da">
                        <div className="head mb-3">
                           <span className="text-danger fw-bold fs-5">
                              Delivery Address
                           </span>
                        </div>
                        <div className="text-wrap mb-4 p-3 rounded bg-neutral-950 text-white">
                           <small>From</small> <br />
                           <span className="fw-bold">{route.pickup}</span>
                        </div>
                        <div className="text-wrap p-3 rounded bg-neutral-950 text-white">
                           <small>Destination</small> <br />
                           <span className="fw-bold">{route.destination}</span>
                        </div>
                     </div>

                     {/* Tracking Detail */}
                     <div className="ta my-5 bg-white p-3">
                        <div className="head mb-3">
                           <span className="text-danger fw-bold fs-5">
                              Tracking Details
                           </span>
                        </div>
                        <div className="details d-flex flex-wrap">
                           <div className="text-wrap col">
                              <small>Tracking Number</small> <br />
                              <span className="fw-bold">{tracking_id}</span>
                           </div>
                           <div className="text-wrap col">
                              <small>Date</small> <br />
                              <span className="fw-bold">{route.date}</span>
                           </div>
                        </div>
                        <div className="btn-wrap py-4">
                           <button
                              className="btn btn-danger text-sm fw-bold "
                              onClick={() =>
                                 navi(
                                    `/tracking/details?tracking_id=${tracking_id}`
                                 )
                              }
                           >
                              See tracking details
                           </button>
                        </div>
                     </div>

                     {/* Tracking Slide */}
                     <div className="wrap">
                        <div className="head mb-3">
                           <span className="text-danger fw-bold fs-5">
                              Package Location
                           </span>
                        </div>
                        <div className="grid grid-cols-4 gap-1 text-white bg-gray-200 p-3 rounded-xl mb-20">
                           <div className="wrap flex items-center gap-3 col-span-2">
                              <div className="wrap-icon">
                                 <TiArrowRightOutline className="blink" size={"50px"} />
                              </div>
                              <div className="bg-red-500 rounded-xl p-2 uppercase flex items-center w-full h-full">
                                 <marquee><span className="font-bold">{recentCountry}</span></marquee>
                              </div>
                           </div>
                           <div className="wrap flex flex-col col-span-2">
                              {/* <p className="font-bold text-red-500">Info.</p> */}
                              <div className="bg-black rounded-xl p-2 uppercase flex items-center h-full">
                                 <marquee><span>{recentMessage}, <span>{city}</span></span></marquee>
                              </div>
                           </div>
                           {/* <div className="wrap flex flex-col">
                              <p className="font-bold text-red-500">City</p>
                              <div className="wrap bg-black font-bold rounded-xl p-2 uppercase flex items-center h-full">
                                 <marquee><span>{city}</span></marquee>
                              </div>
                           </div> */}
                        </div>
                     </div>

                     {/* Tracking History */}
                     {routeData.route.reverse().map((rte, index) => (
                        <div
                           className="wrap position-relative row pb-4 align-items-center"
                           key={index}
                        >
                           <div
                              className="col-1 icon justify-content-center d-flex align-items-center"
                              style={{ height: "100%" }}
                           >
                              <div className="circle bg-danger"></div>
                              <div className="d-flex col-1 p-0 d-flex justify-content-center">
                                 <div className="line"></div>
                              </div>
                           </div>
                           <div className="content col-lg-11 col-11 bg-white py-4 rounded-xl">
                              <div className="country fw-bold text-xl bg-neutral-900 rounded-t-xl p-3 mb-3 text-white">
                                 <span className="mb-3">{rte.country}</span>
                              </div>
                              <div className="list">
                                 <ul>
                                    {rte.path.reverse().map((pth, index) => {
                                       if (index === 0) return null;
                                       return (
                                          <li className="mb-3 bg-red-40 ml-5 list-disc" key={index}>
                                             <div className="row font-thin">
                                                <div className="text-wrap col-lg-9 col-8">
                                                   <span className=" col-12">
                                                      {pth.message}
                                                      <br />
                                                      <b className="text-red-500">{pth.location}</b>
                                                   </span>{" "}
                                                   <br />
                                                </div>
                                                <div className="date-wrap col-lg-3 col-3">
                                                   <span className="fw-bold  mb-1">
                                                      {pth.futureTime}
                                                   </span>{" "}
                                                   <br />
                                                   <small className="">
                                                      {pth.futureDate}
                                                   </small>
                                                </div>
                                             </div>
                                          </li>
                                       )

                                    })}
                                 </ul>
                              </div>
                           </div>
                        </div>
                     ))}
                     {routeData.route.length === 0 && <RouteSkeleton />}
                  </div>
               )}
            </div>
         </div>
         <Footer />
      </div >
   );
}

const Map = ({ query }) => {
   return (
      <iframe
         width="100%"
         height="200"
         title="things"
         frameborder="0"
         scrolling="no"
         marginheight="0"
         marginwidth="0"
         src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Belgium&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      >
         <a href="https://www.maps.ie/population/">Population mapping</a>
      </iframe>
   );
};

export default TrackingShipment;
