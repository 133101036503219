import React from "react";
import Container from "./Container";
import { Link } from "react-router-dom";

export default function Abt() {
   return (
      <div className="bg-gray-200 py-16">
         <Container>
            <div className="grid lg:grid-cols-2 items-center gap-5">
               <div className="img-con">
                  <img src="/abt1.png" alt="sperckz" />
               </div>
               <div className="wrap">
                  <div className="heading">
                     <div className="flex gap-2">
                        <div className="wrap">
                           <hr className="my-1 w-[70px] text-red-500 border-2" />
                           <hr className="my-1 w-[50px] text-red-500 border-2" />
                           <hr className="my-1 w-[30px] text-red-500 border-2" />
                        </div>
                        <span className="text-xl font-bold text-red-500 uppercase">
                           About Sperckz Logistics
                        </span>
                     </div>
                     <div className="text-wrap text-4xl font-extrabold my-3">
                        <span>We are logistics & transport company</span>
                     </div>
                  </div>

                  <div className="flex items-center gap- my-4 shadow-lg bg-slate-900 rounded-md overflow-hidden">
                     <div className="text-wrap text-white p-3 text-5xl font-bold text-center">
                        <span>11+</span>
                     </div>
                     <div className="text-wrap p-3 bg-white">
                        <div className="heading">
                           <span className="text-2xl font-bold">
                              Years Experience
                           </span>{" "}
                           <div className="mt-2">
                              <span>
                                 We have a deep commitment to always delivering first-class service. Whether we’re in the office, the warehouse, or on the road, our six core values drive us.
                              </span>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="btn-wrap">
                     <Link to={'/about'}>
                        <button className="bg-red-500 rounded-lg text-lg text-white p-2 px-3 font-thin hover:bg-red-600 col-md-6 col-lg-4 hover:shadow-md">
                           Read More
                        </button>
                     </Link>
                  </div>
               </div>
            </div>
         </Container>
      </div>
   );
}
