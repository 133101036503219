import React from "react";
import { Link } from "react-router-dom";

export default function QuoteBg() {
   return (
      <div className="wrap bg-quote text-center">
         <div className="bg-wrap bg-pri bg-opacity-80 py-32">
            <div className="text-wrap leading-10 font-extrabold text-4xl text-white">
               <span>Get Started With Sperckz logistics today</span> <br />
               <span>Transport you package</span>
            </div>
            <div className="btn-wrap mt-4">
               <Link to={'/#'}>
                  <button className="bg-white p-3 rounded-md font-bold">
                     Get Started
                  </button>
               </Link>
            </div>
         </div>
      </div>
   );
}
