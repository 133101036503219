import React from 'react'
import AbtIntro from '../Components/about/AbtIntro'
import QuoteBg from '../Components/about/QuoteBg'
import Special from '../Components/about/Special'
import Breadcrumb from '../Components/Breadcrumb'
import ContactHaader from '../Components/ContactHaader'
import Footer from '../Components/Footer'
import StickyHeader from '../Components/Header'

export default function About() {
  return (
    <div className="about">
        <StickyHeader/>
        <Breadcrumb title={"About Us"} />
        <AbtIntro />
        <QuoteBg />
        <Special />
        <Footer />
    </div>
  )
}