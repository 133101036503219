import React, { useState } from 'react';
import { FaSearch, FaTimesCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import '../../../TrackBox.css'; // Import CSS file for additional styling if needed

export default function TrackBox({ handleFadeOut, isVisible }) {
  const navi = useNavigate()
  const [trackingId, setTrackingId] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()

    navi(`/tracking/id?tracking_id=${trackingId}`)
  }

  return (
    <div className={`wrap absolute top-0 bottom-0 right-0 left-0 bg-neutral-950 bg-opacity-80 backdrop-blur-md flex items-center justify-center ${isVisible ? 'fade-in' : 'fade-out'}`}>
      {isVisible && (
        <form onSubmit={handleSubmit}>
          <div className="flex flex-wrap items-center">
            <div className="closer col-12 mb-3 text-lg flex justify-end">
              <span className='flex rounded-full cursor-pointer' onClick={() => handleFadeOut()}><FaTimesCircle /></span>
            </div>
            <input type="number" className=' bg-neutral-950 lg:w-[400px] rounded-l-3xl p-3' placeholder='Tracking ID' value={trackingId} onChange={(e) => setTrackingId(e.target.value)} required />
            <div className="bg-pri p-3 px-4 rounded-r-3xl">
              <button className='h-100'> <FaSearch /></button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
