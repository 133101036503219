import React from "react";
import { FaDollarSign, FaPhone, FaUsers } from "react-icons/fa";
import CardComp from "./CardComp";
import Container from "./Container";

export default function Card() {
   const cardFunc = (icon, title, text) => {
      return { icon, title, text }
   }
   const cardArr = [
      cardFunc(<FaPhone />, "24/7 AVAILABLE", "Count on us for round-the-clock logistics support, available every day of the week, anytime you need assistance."),
      cardFunc(<FaUsers />, "EXPERT STAFF", "Skilled experts ready to assist you with all your logistics needs."),
      cardFunc(<FaDollarSign />, "LOW COST", "Orders are cost efficient. We deliver quality service at reasonable price. "),
   ]
   return (
      <div className="wrap">
         <Container>
            <div className="grid lg:grid-cols-3 gap-3 py-16">
               {cardArr.map((i, index) => (
                  <CardComp
                     key={index}
                     icon={i.icon}
                     heading={i.title}
                     text={i.text}
                  />
               ))}
            </div>
         </Container>
      </div>
   );
}
