import React from "react";

export default function RouteSkeleton() {
   return (
      <div className="r-skeleton">
         <div className="wrap position-relative row pb-4 align-items-center placeholder-glow">
            <div
               className="col-1 icon justify-content-center d-flex align-items-center"
               style={{ height: "100%" }}
            >
               <div className="circle bg-danger"></div>
               <div className="d-flex col-1 p-0 d-flex justify-content-center">
                  <div className="line"></div>
               </div>
            </div>
            <div className="content col-lg-11 col-11">
               <div className="country fs-6 fw-bold">
                  <span className="placeholder col-12 mb-3">USA</span>
               </div>
               <div className="list">
                  <ul>
                     <li>
                        <div className="row">
                           <div className="text-wrap col-lg-9 col-8 placeholder">
                              <span className="placeholder col-12">
                                 Loading
                              </span>{" "}
                              <br />
                              <small className="placeholder">
                                 <small className="fw-bold">Status:</small> On
                                 Hold
                              </small>
                           </div>
                           <div className="date-wrap col-lg-2 col-3">
                              <span className="fw-bold placeholder mb-1">
                                 4:30 PM
                              </span>{" "}
                              <br />
                              <small className="placeholder">
                                 16 Sep, 2023
                              </small>
                           </div>
                        </div>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
   );
}
