export const RouteInitialState = {
   tracking_id: 0,
   sender_name: "",
   sender_email: "",
   sender_phoneNo: "",
   sender_address: "",
   reciever_name: "",
   reciever_email: "",
   reciever_phoneNo: "",
   reciever_address: "",
   name_of_parcel: "",
   weight: "",
   pickup: "",
   destination: "",
};

export const RouteReducer = (state, action) => {
   switch (action.type) {
      case "sender_details":
         return {
            ...state,
            sender_name: action.payload.sender_name,
            sender_email: action.payload.sender_email,
            sender_phoneNo: action.payload.sender_phoneNo,
            sender_address: action.payload.sender_address,
         };

      case "reciever_details":
         return {
            ...state,
            reciever_name: action.payload.reciever_name,
            reciever_email: action.payload.reciever_email,
            reciever_phoneNo: action.payload.reciever_phoneNo,
            reciever_address: action.payload.reciever_address,
         };
      case "package_details":
         return {
            ...state,
            tracking_id: action.payload.trackind_id,
            name_of_parcel: action.payload.name_of_parcel,
            weight: action.payload.weight,
         };
      case "delivery_details":
         return {
            ...state,
            pickup: action.payload.pickup,
            destination: action.payload.destination,
         };
      default:
         break;
   }
};
