import Container from "./Container";
import TestimonyBox from "./TestimonyBox";

export default function Testimony() {
   const dum = [1, 1, 1];
   const cardFunc = (text, img, name, role) => {
      return { text, img, name, role }
   }
   const cardArr = [
      cardFunc("Sperckz Logistics surpassed my expectations! Efficient, timely, and cost-effective. Highly recommended",
         "https://img.freepik.com/free-photo/cheerful-african-guy-with-narrow-dark-eyes-fluffy-hair-dressed-elegant-white-shirt_273609-14082.jpg?w=826&t=st=1708100893~exp=1708101493~hmac=d421120464b5a878d3de6fb80429207a431aade625ce4b12a11274b4102d5380",
         "Emily Thompson",
         "Supply Chain Manager"
      ),
      cardFunc("Impressed by Sperckz Logistics' professionalism and vast warehouse. Reliable service from start to finish i'd really recommend them",
"https://as1.ftcdn.net/v2/jpg/03/32/38/72/1000_F_332387269_d9jwaESXJjeSMrwm3GokuOG9Bi8DDyBh.jpg",
         "David Kim",
         "Distribution Coordinator"
      ),
      cardFunc("Sperckz Logistics made shipping hassle-free! Affordable, attentive, and available 24/7. Trusted them with all my needs.",
         "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
         "Sarah Johnson",
         "Logistics Specialist"
      )
   ]
   return (
      <div className="wrap py-16">
         <Container>
            <div className="heading mb-3">
               <div className="flex gap-2 justify-center">
                  <div className="wrap">
                     <hr className="my-1 w-[70px] text-red-500 border-2" />
                     <hr className="my-1 w-[50px] text-red-500 border-2" />
                     <hr className="my-1 w-[30px] text-red-500 border-2" />
                  </div>
                  <span className="text-xl font-bold text-red-500 uppercase">
                     What our customer have to say
                  </span>
               </div>

               <div className="text-wrap text-center">
                  <div className="text-wrap text-md col-lg-6 mx-auto mt-3 font-thin text-pri">
                     <span>
                        Sperckz logistics have worked with numerous client and small businesses. Here are some words from our clients.
                     </span>
                  </div>
               </div>
            </div>
            <div className="grid mb:grid-cols-2 lg:grid-cols-3 gap-4">
               {cardArr.map((i, index) => (
                  <TestimonyBox text={i.text} img={i.img} name={i.name} role={i.role} key={index} />
               ))}
            </div>
         </Container>
      </div>
   );
}
