import React, { useReducer, useState } from 'react'
import { post } from '../../../utils/postCaller'
import { BarLoader } from 'react-spinners'
import notify from '../../../utils/notify'

export default function RouteReducerDisplay({ routeData }) {
    const [loading, setLoading] = useState(false)
    const handleSubmit = async () => {
        try {
            setLoading(true)
            const data = {
                destination: routeData.destination, pickup: routeData.pickup, route: routeData.route
            }
            const response = await post("/create-route", data)
            notify('success', "Route Added Successfully")
            console.log(response)
        } catch (error) {
            console.log(error)
            notify('error', error.response.data.message)
        } finally {
            setLoading(false)
        }
    }
    return (
        <div className='shadow-sm p-3 rounded-3xl bg-white'>
            <div className="header mb-3">
                <h1 className='font-extrabold text-xl text-red-500'>Route Creation Display</h1>

                <div className="flex gap-3 mt-2">
                    <div className="wrap text-sm w-full">
                        <span>Pickup</span>
                        <p className='text-lg font-bold'>{routeData?.pickup}</p>
                    </div>
                    <div className="wrap text-sm w-full">
                        <span>Destination</span>
                        <p className='text-lg font-bold'>{routeData?.destination}</p>
                    </div>
                </div>
            </div>
            {routeData?.route.length > 0 && (
                <>
                    {routeData.route.map((rte, index) => (
                        <div
                            className="wrap position-relative row pb-4 align-items-center"
                            key={index}
                        >
                            <div
                                className="col-1 icon justify-content-center d-flex align-items-center"
                                style={{ height: "100%" }}
                            >
                                <div className="circle bg-danger"></div>
                                <div className="d-flex col-1 p-0 d-flex justify-content-center">
                                    <div className="line"></div>
                                </div>
                            </div>
                            <div className="content col-lg-11 col-11">
                                <div className="country fs-6 fw-bold">
                                    <span className=" mb-3">{rte?.country}</span>
                                </div>
                                <div className="list">
                                    <ul>
                                        {rte?.path.map((pth, index) => (
                                            <li className="mb-3" key={index}>
                                                <div className="row">
                                                    <div className="text-wrap col-lg-9 col-8">
                                                        <span className=" col-12">
                                                            {pth?.message} <br />
                                                            <b>{pth?.location}</b>
                                                        </span>{" "}
                                                        <br />
                                                    </div>
                                                    <div className="date-wrap col-lg-3 col-3">
                                                        <span className="fw-bold  mb-1">
                                                            {pth?.futureTime}
                                                        </span>{" "}
                                                        <br />
                                                        <small className="">
                                                            {pth?.futureDate}
                                                        </small>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            )}
            {routeData?.route.length > 0 && routeData?.pickup && routeData?.destination && (
                <div className="btn-wrap flex items-center justify-center">
                    <button className='btn hover:bg-neutral-800 p-2 bg-neutral-900 text-white' onClick={handleSubmit}> {loading ? <BarLoader width={'100px'} color='#fff' /> : "Create Route"}</button>
                </div>
            )}
        </div>
    )
}
