import React, { useEffect, useRef } from 'react';

const GoogleTranslate = () => {
    const translateRef = useRef(null);

    useEffect(() => {
        const addGoogleTranslateScript = () => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
            document.body.appendChild(script);

            window.googleTranslateElementInit = () => {
                new window.google.translate.TranslateElement(
                    {
                        pageLanguage: 'en',
                        includedLanguages: 'ar,es,en,de,bn,nl,fr,pt,km,zh-CN,el,hi,ta,te,kn,ml,mr,gu,pa,ur,id,it,ja,lo,ms,my,ne,tl,af,zu,ko,zh-TW,th,tr,vi,sw', // Specify the languages you want to include
                        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
                    },
                    'google_translate_element'
                );
            };
        };

        addGoogleTranslateScript();
    }, []);

    const handleTranslateClick = () => {
        if (translateRef.current) {
            const translateElement = translateRef.current.querySelector('.goog-te-gadget-simple');
            if (translateElement) {
                translateElement.click();
            }
        }
    };

    return (
        <div>
            <button
                className='flex items-center justify-center gap-1 bg-blue-500 p-2 rounded-full text-white'
                onClick={handleTranslateClick}
            >
                Select language <img src="/google.png" alt="google.com" className='w-[30px] rounded-full' />
            </button>
            <div id="google_translate_element" hidden ref={translateRef}></div>
        </div>
    );
};

export default GoogleTranslate;
