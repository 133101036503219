import React from "react";
import ChooseCard from "./ChooseCard";
import { FaCar, FaDollarSign, FaHeadset, FaHome, FaMapMarked, FaMapPin, FaPhone, FaTruck, FaUsers } from "react-icons/fa";

export default function ChooseUs() {
   const dum = [1, 2, 3];
   const cardFunc = (icon, title, text) => {
      return { icon, title, text }
   }
   const cardArr1 = [
      cardFunc(<FaHome />, "SECURED WAREHOUSE", "At Sperckz we ensure clients packages are safe and secure in our warehouse. "),
      cardFunc(<FaCar />, "GOODS TRACKING SUPPORTF", "Tracking Packages is our expertise, and we guarantee safe trip for all packages."),
      cardFunc(<FaTruck />, "LOGISTIC SERVICES", "We provide quality logistics services and ensure every client are satisfied."),
   ]
   const cardArr2 = [
      cardFunc(<FaHeadset />, "CUSTOMER SUPPORT", "We provide support, Kindly visit the contact page to get in contact with us."),
      cardFunc(<FaTruck />, "DELIVERY IN TIME", "Our primary focus and aim is to make sure packages is delivered on time. "),
      cardFunc(<FaMapPin />, "LARGE DESTINATIONS", "We deliver to any where around the world. Close or long distance? Not a barrier."),
   ]
   return (
      <div className="wrap py-32 bg-neutral-950 text-white">
         {/* Heading section */}
         <div className="heading text-center p-3">
            <span className="text-3xl font-extrabold text-red-500">WHY YOU CHOOSE US</span>
            <div className="mt-3 col-lg-6 mx-auto">
               <span>
                  Why Choose Sperckz Logistics? Expert staff, low cost, and 24/7 support for seamless logistics solutions tailored to your needs.
               </span>
            </div>
         </div>

         <div className="grid lg:grid-cols-3 container mx-auto items-center gap-3">
            <div className="wrap">
               {cardArr1.map((i, index) => (
                  <ChooseCard
                     key={index}
                     icon={i.icon}
                     title={i.title}
                     text={i.text} />
               ))}
            </div>
            <div className="img-wrap">
               <img src="/truck.jpeg" alt="Cargo" />
            </div>
            <div className="wrap">
               {cardArr2.map((i, index) => (
                  <ChooseCard
                     l={true} key={index}
                     icon={i.icon}
                     title={i.title}
                     text={i.text}
                  />
               ))}
            </div>
         </div>
      </div>
   );
}
