import React from "react";
import { FaHome } from "react-icons/fa";

export default function ChooseCard({ l, icon, text, title }) {
   return (
      <div className={`wrap flex gap-3 ${l && "flex-row-reverse"} my-5`}>
         <div className="text-wrap">
            <div
               className={`heading ${
                  l ? "text-left" : "text-right"
               } text-lg font-bold`}
            >
               <span>{title}</span>
            </div>
            <div className={`body ${l ? "text-left" : "text-right"} font-thin`}>
               <span>
                  {text}
               </span>
            </div>
         </div>
         <div className="icon-wrap border-2 border-red-500 text-red-500 p-3 flex items-center justify-center text-4xl">
            <span>
               {icon}
            </span>
         </div>
      </div>
   );
}
