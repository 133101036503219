import React, { useEffect } from "react";

const MapComponent = () => {
   useEffect(() => {
      const initMap = () => {
         const directionsRenderer = new window.google.maps.DirectionsRenderer();
         const directionsService = new window.google.maps.DirectionsService();
         const map = new window.google.maps.Map(
            document.getElementById("map"),
            {
               zoom: 7,
               center: { lat: 41.85, lng: -87.65 },
               disableDefaultUI: true,
            }
         );

         directionsRenderer.setMap(map);
         directionsRenderer.setPanel(document.getElementById("sidebar"));

         const control = document.getElementById("floating-panel");

         map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(
            control
         );

         const onChangeHandler = () => {
            calculateAndDisplayRoute(directionsService, directionsRenderer);
         };

         document
            .getElementById("start")
            .addEventListener("change", onChangeHandler);
         document
            .getElementById("end")
            .addEventListener("change", onChangeHandler);
         document
            .getElementById("mode")
            .addEventListener("change", onChangeHandler);
      };

      const calculateAndDisplayRoute = (
         directionsService,
         directionsRenderer
      ) => {
         const start = document.getElementById("start").value;
         const end = document.getElementById("end").value;
         const selectedMode = document.getElementById("mode").value;

         directionsService
            .route({
               origin: start,
               destination: end,
               travelMode: window.google.maps.TravelMode[selectedMode],
            })
            .then((response) => {
               directionsRenderer.setDirections(response);
            })
            .catch(() => {
               window.alert("Directions request failed");
            });
      };

      // Load Google Maps API script
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAEqOy5BAnNWx_ayZuJyNH8RqHAZBiqtr0&callback=initMap&v=weekly`;
      script.defer = true;
      document.head.appendChild(script);

      return () => {
         document.head.removeChild(script);
      };
   }, []);

   return (
      <div>
         <div id="floating-panel">
            <strong>Start:</strong>
            <select id="start">
               <option value="chicago, il">Chicago</option>
               <option value="st louis, mo">St Louis</option>
               <option value="joplin, mo">Joplin, MO</option>
               <option value="oklahoma city, ok">Oklahoma City</option>
               <option value="amarillo, tx">Amarillo</option>
               <option value="gallup, nm">Gallup, NM</option>
               <option value="flagstaff, az">Flagstaff, AZ</option>
               <option value="winona, az">Winona</option>
               <option value="kingman, az">Kingman</option>
               <option value="barstow, ca">Barstow</option>
               <option value="san bernardino, ca">San Bernardino</option>
               <option value="los angeles, ca">Los Angeles</option>
            </select>
            <br />
            <strong>End:</strong>
            <select id="end">
               <option value="chicago, il">Chicago</option>
               <option value="st louis, mo">St Louis</option>
               <option value="joplin, mo">Joplin, MO</option>
               <option value="oklahoma city, ok">Oklahoma City</option>
               <option value="amarillo, tx">Amarillo</option>
               <option value="gallup, nm">Gallup, NM</option>
               <option value="flagstaff, az">Flagstaff, AZ</option>
               <option value="winona, az">Winona</option>
               <option value="kingman, az">Kingman</option>
               <option value="barstow, ca">Barstow</option>
               <option value="san bernardino, ca">San Bernardino</option>
               <option value="los angeles, ca">Los Angeles</option>
            </select>
            <br />
            <b>Mode of Travel: </b>
            <select id="mode">
               <option value="DRIVING">Driving</option>
               <option value="WALKING">Walking</option>
               <option value="BICYCLING">Bicycling</option>
               <option value="TRANSIT">Transit</option>
            </select>
         </div>
         <div id="container">
            <div id="map"></div>
            <div id="sidebar"></div>
         </div>
      </div>
   );
};

export default MapComponent;
