import React from "react";
import StickyHeader from "./Header";

export default function Breadcrumb({ title }) {
    return (
        <div className="bd text-center">
            <div className="bg-con bg-neutral-950 bg-opacity-70 min-h-[70vh] flex items-center justify-center py-10">

                <div className="wrap">
                    <div className="heading font-extrabold text-white text-5xl px-3 mt-5">
                        <span>{title}</span>
                    </div>
                    <div className="location mt-3 text-gray-300 text-lg">
                        Home / <span className="text-red-500">{title}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
