import React from "react";

export default function CardComp({ icon, heading, text }) {
   return (
      <div className="wrap flex gap-3 p-3">
         <div className="icon-wrap">
            <span className="flex p-3 rounded-md text-xl border-1 border-black">{icon}</span>
         </div>
         <div className="text-wrap">
            <div className="text-heading font-bold text-xl">
               <span>{heading}</span>
            </div>
            <div className="text-body text-sm">{text}</div>
         </div>
      </div>
   );
}
