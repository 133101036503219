// import React, { useState } from "react";
// import { FaBars } from "react-icons/fa";
// import { NavLink } from "react-router-dom";
// import Login from "./dashboard/components/login";

// function Sidebar({ children }) {
//    const [sidebarOpen, setSidebarOpen] = useState(false);

//    const toggleSidebar = () => {
//       setSidebarOpen(!sidebarOpen);
//    };
//    const menuFunc = (link, title) => {
//       return { link, title };
//    };

//    const menuArr = [
//       menuFunc("/dashboard", "Dashboard"),
//       menuFunc("/dashboard/create-route", "Create Route"),
//       menuFunc("/tracking/list", "Tracking List"),
//       menuFunc("/", "Home"),
//    ];

//    return (
//       <div className={`container-fluid`}>
//          <Login />
//          <div className="row">
//             {/* Sidebar */}
//             <nav
//                id="sidebar"
//                className={`p-0   col-md-3 side-menu col-lg-2 d-md-block bg-light sidebar ${
//                   sidebarOpen ? "sidebar-open" : ""
//                }`}
//             >
//                <div className="position-sticky h-100">
//                   <ul className="nav d-flex  align-items-center justify-content-center bg-dark h-100 flex-column">
//                      <div className="cancel d-md-none fs-3 fw-bold bg-dark col-6 text-right d-flex justify-content-end mb-5">
//                         <span
//                            className="text-dark fw-bold bg-danger rounded px-3 pb-1"
//                            onClick={toggleSidebar}
//                         >
//                            x
//                         </span>
//                      </div>
//                      {menuArr.map((menu, index) => (
//                         <li className="nav-item mb-2" onClick={toggleSidebar}>
//                            <NavLink
//                               className="nav-link active"
//                               to={menu.link}
//                               key={index}

//                            >
//                               {menu.title}
//                            </NavLink>
//                         </li>
//                      ))}
//                   </ul>
//                </div>
//             </nav>

//             {/* Menu toggle btn */}
//             <div className="btn-wrap col-12 bg-danger d-flex align-items-center justify-content-end px-3 p-1">
//                <button
//                   className="btn px-4 py-2 fs-4 btn-dark text-light "
//                   onClick={toggleSidebar}
//                >
//                   <FaBars />
//                </button>
//             </div>

//             {/* Main content */}
//             <main className="col ms-sm-auto col px-md-4">{children}</main>
//          </div>
//       </div>
//    );
// }

// export default Sidebar;


import React, { useState } from "react";
import {
   FaBars,
   FaHome,
   FaSearch,
   FaUserGraduate,
   FaChalkboard,
   FaUser,
   FaTruck,
   FaRoad,
   FaBuilding,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import Login from "./dashboard/components/login";

export default function Sidebar({ children }) {
   const dum = [1, 2, 3, 4, 5, 3, 3];
   const menuFunc = (icon, text, link) => {
      return { icon, text, link };
   };

   const menuArr = [
      menuFunc(<FaBuilding />, "Dashboard", "/dashboard"),
      menuFunc(<FaRoad />, "Create Route", "/create-route"),
      menuFunc(<FaTruck />, "Shipment", "/tracking/list"),
      menuFunc(<FaHome />, "Home", "/"),
   ];
   const [show, setShow] = useState(true);
   return (
      <div className="menu bg-grey">
         <Login />
         <div className="flex">
            <div
               className={`side-menu lg:w-[300px] h-[100vh] bg-gray-100 relative ${!show && "d-none"
                  }`}
            >
               <div className="wrap bg-gray-50   fixed z-10 top-0 bottom-0 w-[80%] lg:w-[250px]">
                  <div className="logo-con text-lg font-bold text-center py-4 flex gap-2 items-center p-2">
                     <img src="/ficon.png" className="w-[40px]" alt="Sperckz Logistics" /><span>Sperckz Logistic</span>
                  </div>
                  <div className="menu-con px-2">
                     <div className="label text-sm font-bold pt-2 text-gray-500">
                        <span>Main Menu</span>
                     </div>
                     <div className="menu-list mt-3">
                        <ul>
                           {menuArr.map((menu, index) => (
                              <li key={index} className="flex">
                                 <NavLink
                                    to={menu.link}
                                    className="flex mb-4 p-0 w-full"
                                 >
                                    <li
                                       className={` text-gray-500 px-2 hover:bg-slate-300 hover:rounded-lg hover:text-primary w-full`}
                                    >
                                       {" "}
                                       <a
                                          className={`d-flex gap-2 align-items-center  py-2 px-3 rounded-lg`}
                                       >
                                          {menu.icon} {menu.text}
                                       </a>
                                    </li>
                                 </NavLink>
                              </li>
                           ))}
                        </ul>
                     </div>
                  </div>
               </div>
            </div>

            {/* Content Section */}
            <div className="content w-100 min-h-[100vh]">
               <div className="header bg-gray-50 py-2">
                  <div className="flex lg:flex-row-reverse container justify-between items-center">
                     {/* User Img */}
                     <div className="profile">
                        <div className="flex items-center">
                           <div className="img-wrap">
                              {/* <img
                                 src="/avatar.jpeg"
                                 alt="Profile"
                                 className="w-[30px] h-[30px] rounded-full border-pri border-3"
                              /> */}
                              <FaUser size={25} />
                           </div>
                           <div className="text-wrap px-3">
                              <span className="font-bold">Raynold Taylor</span>{" "}
                              <br />
                              <span className="text-sm text-pri">Admin</span>
                           </div>
                        </div>
                     </div>

                     {/* search bar */}
                     <div className="search-bar hidden md:block">
                        <div className="wrap flex gap-2 items-center bg-grey px-3 rounded-lg">
                           <FaSearch className="text-2xl" />{" "}
                           <input
                              type="text"
                              className="border-0 px-3  py-2 w-full bg-grey"
                              placeholder="Search"
                           />
                        </div>
                     </div>

                     <div
                        className="menu-bar bg-pri p-2 rounded-lg"
                        onClick={() => setShow(!show)}
                     >
                        <span className=" text-white">
                           <FaBars />{" "}
                        </span>
                     </div>
                  </div>
               </div>

               {/* Page Content */}
               <div className="w-100">{children}</div>
            </div>
         </div>
      </div>
   );
}