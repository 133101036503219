import React, { useContext, useEffect, useState } from "react";
import { RouterContext } from "../../../utils/context/RouterContext";

export default function Reciever() {
   const { state, dispatch } = useContext(RouterContext);

   // Input state
   const [reciever_name, setReciever_name] = useState("");
   const [reciever_email, setReciever_email] = useState("");
   const [reciever_phoneNo, setReciever_phoneNo] = useState("");
   const [reciever_address, setReciever_address] = useState("");

   // update the senderData when input changes
   useEffect(() => {
      updateRecieverData();
   }, [reciever_name, reciever_email, reciever_phoneNo, reciever_address]);

   // updates the reciever data in the reducer.
   const updateRecieverData = () => {
      dispatch({
         type: "reciever_details",
         payload: {
            reciever_name,
            reciever_email,
            reciever_phoneNo,
            reciever_address,
         },
      });
      console.log(state)
   };
   return (
      <div className="receiver my-3">
         <div className="container">
            <div className="col-lg-6 p-3 form-wrap shadow mx-auto">
               <div className="head">
                  <span className="fs-5 fw-bold">Reciever Details</span>
               </div>
               <div className="body">
                  <div className="form-group">
                     <div className="row">
                        <div className="col-md-6">
                           <input
                              type="text"
                              placeholder="Reciever's Name *"
                              className="form-control mb-3"
                              required
                              value={reciever_name}
                              onChange={(e) => setReciever_name(e.target.value)}
                           />
                        </div>
                        <div className="col-md-6">
                           <input
                              type="text"
                              placeholder="Reciever's Address *"
                              className="form-control mb-3"
                              required
                              value={reciever_address}
                              onChange={(e) =>
                                 setReciever_address(e.target.value)
                              }
                           />
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-md-6">
                           <input
                              type="email"
                              placeholder="Reciever's Email *"
                              className="form-control mb-3"
                              required
                              value={reciever_email}
                              onChange={(e) =>
                                 setReciever_email(e.target.value)
                              }
                           />
                        </div>
                        <div className="col-md-6">
                           <input
                              type="number"
                              placeholder="Reciever's Phone No *"
                              className="form-control mb-3"
                              required
                              value={reciever_phoneNo}
                              onChange={(e) =>
                                 setReciever_phoneNo(e.target.value)
                              }
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
