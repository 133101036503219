import React from "react";
import { useNavigate } from "react-router-dom";
import RouterContextWrap from "../../utils/context/RouterContext";
import Sidebar from "../SideBar";
import Delivery from "./components/Delivery";
import Reciever from "./components/Reciever";
import Sender from "./components/Sender";

function Dashboard() {
   const navi = useNavigate();
   return (
      <Sidebar>
         <div className="container mt-5">
            <div className="form-wrap">
               <Sender /> <br />
               <Reciever />
               <div className="btn-wrap col-lg-6 mx-auto p-2">
                  <button
                     className="btn btn-danger col-12 shadow"
                     onClick={() => navi("delivery")}
                  >
                     Next
                  </button>
               </div>
            </div>
         </div>
      </Sidebar>
   );
}

export default Dashboard;
