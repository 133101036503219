import React, { useContext, useEffect, useState } from "react";
import Route from "./Route";
import { RouterContext } from "../../../utils/context/RouterContext";
import Sidebar from "../../SideBar";

export default function Delivery() {
   const { state, dispatch } = useContext(RouterContext);

   const [displayRoute, setdisplayRoute] = useState(false);
   const [trackingId, setTrackingId] = useState("");

   // Input Fields state
   const [name_of_parcel, setName_of_parcel] = useState("");
   const [weight, setWeight] = useState("");

   // update product data on input change
   useEffect(() => {
      productData();
   }, [name_of_parcel, weight, trackingId]);

   const productData = () => {
      dispatch({
         type: "package_details",
         payload: { trackind_id: trackingId, name_of_parcel, weight },
      });
      console.log(state);
   };

   function generateRandomCode(length) {
      const characters = "0123456789";
      let randomCode = "";

      for (let i = 0; i < length; i++) {
         const randomIndex = Math.floor(Math.random() * characters.length);
         randomCode += characters.charAt(randomIndex);
      }
      setTrackingId(randomCode);
      return randomCode;
   }

   return (
      <Sidebar>
         <div className="delivery">
            <div className="container">
               <div className=" col-lg-6 p-3 form-wrap shadow mx-auto">
                  <div className="head">
                     <span className="fs-5 fw-bold">Package Details</span>
                  </div>
                  <div className="body">
                     <div className="form-group">
                        <div className="row mb-3">
                           <div className="col-md-8">
                              <input
                                 type="number"
                                 placeholder="12345..."
                                 className="form-control mb-3"
                                 value={trackingId}
                                 required
                              />
                           </div>
                           <div className="col-md-4">
                              <div className="btn-wrap">
                                 <button
                                    className="btn btn-danger"
                                    onClick={() => generateRandomCode(10)}
                                 >
                                    Generate Tracking ID
                                 </button>
                              </div>
                           </div>
                        </div>
                        {/* Weight and Name */}
                        <div className="row mb-3">
                           <div className="col-md-12">
                              <input
                                 type="text"
                                 placeholder="Name on Parcel *"
                                 className="form-control"
                                 required
                                 value={name_of_parcel}
                                 onChange={(e) =>
                                    setName_of_parcel(e.target.value)
                                 }
                              />
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-md-12">
                              <input
                                 type="number"
                                 placeholder="Weight (kg) *"
                                 className="form-control"
                                 required
                                 value={weight}
                                 onChange={(e) => setWeight(e.target.value)}
                              />
                           </div>
                        </div>
                        {!displayRoute && (
                           <div
                              className="btn-wrap col-lg-6 mx-auto p-2"
                              onClick={() => setdisplayRoute(true)}
                           >
                              <button className="btn btn-danger col-12 shadow">
                                 Next
                              </button>
                           </div>
                        )}
                        {displayRoute && <Route />}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Sidebar>
   );
}
