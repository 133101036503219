import React, { useState } from "react";
import StickyHeader from "./Header";
import TrackBox from "./TrackBox";
import GoogleTranslate from "./GoogleTranslate";

export default function Hero() {
   const [isVisible, setIsVisible] = useState(false);

   const handleFadeOut = () => {
      setIsVisible(!isVisible);
      console.log(isVisible)
   };

   return (
      <div className="hero relative">
         <div className="bg-con text-white bg-neutral-950 bg-opacity-70">
            <StickyHeader />
            <div className="flex items-center justify-center h-full min-h-[100vh]">
               <div className="wrap">
                  <div className="text-wrap text-center">
                     <span className="text-lg font-thin capitalize">
                        Welcome to Sperckz Logistics{" "}
                     </span>{" "}
                     <br />
                     <span className="text-7xl font-extrabold">
                        FAST - SECURE - RELIABLE
                     </span>
                  </div>
                  <div className="btn-wrap flex justify-center mt-4 gap-4">
                     <a href="mailto:sperckz@sperckz.com">
                        <button className="bg-red-500 text-white font-thin p-2 px-3 text-lg ">
                           Contact US
                        </button>
                     </a>
                     <button className="bg-pri text-white font-thin p-2 px-3 text-lg " onClick={() => handleFadeOut()}>
                        Track Package
                     </button>
                  </div>
                  <div className="wrap flex justify-center mt-4">
                     <GoogleTranslate />
                  </div>
               </div>
            </div>
            <TrackBox handleFadeOut={handleFadeOut} isVisible={isVisible} />
         </div>
      </div>
   );
}
