import React, { createContext, useReducer } from "react";
import { RouteInitialState, RouteReducer } from "./RouterReducer";

export const RouterContext = createContext();
export default function RouterContextWrap({ children }) {
   const [state, dispatch] = useReducer(RouteReducer, RouteInitialState);
   return (
      <RouterContext.Provider value={{ state, dispatch }}>
         {children}
      </RouterContext.Provider>
   );
}
