import React, { useEffect, useReducer, useState } from 'react'
import { FaEnvelope } from 'react-icons/fa'
import pathReducer, { CLEARR, addCountry, addPath } from '../../../Context/pathReducer'
import RouteReducerDisplay from './RouteReducerDisplay'
import createRouteReducer, { updateDestination, updatePickup, updateRoutePath } from '../../../Context/CreateRouteReducer'
import Sidebar from '../../SideBar'

export default function CreateRoute() {
    const [state, dispatch] = useReducer(pathReducer)
    const [state2, dispatch2] = useReducer(createRouteReducer)

    const [country, setCountry] = useState('')
    const [message, setMessage] = useState('')
    const [city, setCity] = useState('')

    useEffect(() => console.log(state), [state])

    const handleAddPath = () => {
        const data = {
            message,
            location: `${city}, ${country}`,
            time: ""
        }
        dispatch(addPath(data))
        clear()
    }

    const clear = () => {
        setCity('')
        setMessage('')
    }

    const handleRoutePathUpdate = () => {
        try {
            // const pathLength = state?.path.length
            // if (pathLength === 0) return alert("iiiii")
            dispatch2(updateRoutePath(state))
            dispatch(CLEARR())
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Sidebar>
            <div className="wrap bg-gray-100">
                <div className="container relative">
                    <div className="grid grid-cols-2 py-16">
                        {/* Creating route */}
                        <div className="wrap bg-white p-3 rounded-3xl ">
                            <h1 className='font-bold mb-2 text-xl'>Route Path</h1>
                            <div className="flex gap-2 mb-4 w-full">
                                <div className="input-wrap text-sm w-full">
                                    <label>Pickup Locaton</label>
                                    <input type="text" className='form-control w-full mt-2' value={state2?.pickup} onChange={(e) => dispatch2(updatePickup(e.target.value))} />
                                </div>
                                <div className="input-wrap text-sm w-full">
                                    <label>Desitination Locaton</label>
                                    <input type="text" className='form-control w-full mt-2' value={state2?.destination} onChange={(e) => dispatch2(updateDestination(e.target.value))} />
                                </div>
                            </div>

                            <div className="flex justify-between">
                                <h1 className='font-bold mb-2 text-xl'>Country Path</h1>
                                {state?.path && (
                                    <>
                                        <span className='text-sm text-red-500'>pending ({state.path.length})</span>
                                    </>
                                )}
                            </div>
                            <div className="input-wrap text-sm">
                                <label>Country</label>
                                <div className="flex gap-2 mt-2">
                                    <input type="text" className='form-control w-1/2' value={country} onChange={(e) => setCountry(e.target.value)} />
                                    {state?.country !== country && (
                                        <>
                                            <button className='bg-red-500 text-white px-3 rounded-xl py-2' onClick={() => dispatch(addCountry(country))}>Add</button>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="input-wrap text-sm mt-2">
                                <label>Message</label>
                                <input type="text" className='form-control w-full mt-2' value={message} onChange={(e) => setMessage(e.target.value)} />
                            </div>
                            <div className="flex gap-2 mb-4 w-full mt-2">
                                <div className="input-wrap text-sm w-full">
                                    <label>City</label>
                                    <input type="text" className='form-control w-full mt-2' value={city} onChange={(e) => setCity(e.target.value)} />
                                </div>
                                <div className="input-wrap text-sm w-full">
                                    <label>Country</label>
                                    <input type="text" className='form-control w-full mt-2' value={country} />
                                </div>
                            </div>

                            <div className="btn-wrap flex gap-3">
                                <button className='bg-red-500 text-white px-3 rounded-xl py-2 w-full' onClick={handleAddPath}>Add Path</button>
                                {state?.path.length > 0 &&
                                    <button className='bg-green-500 text-black px-3 rounded-xl py-2 w-full' onClick={handleRoutePathUpdate}>Update Route Path</button>
                                }
                            </div>

                        </div>

                        {/* Route display */}
                        <div className="wrap fixed top-0 bottom-0 justify-self-end w-[40%] overflow-y-scroll py-16 mt-[63px]">
                            <RouteReducerDisplay routeData={state2} />
                        </div>
                    </div>
                </div>
            </div>
        </Sidebar>
    )
}
