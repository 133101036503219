import React, { useState } from 'react';
import Container from '../Container';

export default function LeaveMessage() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    const [mailData, setmailData] = useState('')

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { name, email, subject, message } = formData;
        const mailtoLink = `mailto:sperckz@sperckz.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(`Name: ${name}\n\nMessage:\n${message}`)}`;
        alert(mailtoLink)
        window.location.href = mailtoLink;
    };

    return (
        <div className="wrap py-16 bg-gray-100">
            <Container>
                <div className="col-lg-8 mx-auto">
                    <div className="heading mb-3">
                        <span className='text-3xl'><span>Leave </span> <span className=' font-extrabold'>A Message</span></span> <br />
                        <span className='font-thin'>We'll be sure to give you a respond as soon as possible.</span>
                    </div>

                    <form onSubmit={handleSubmit} className="form-wrap" action={mailData}>
                        <div className="flex gap-3">
                            <input
                                type="text"
                                name="name"
                                placeholder='Name'
                                className='p-2 bg-transparent col border-[1px] rounded-md border-black'
                                required
                                value={formData.name}
                                onChange={handleChange}
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder='Email'
                                className='p-2 bg-transparent col border-[1px] rounded-md border-black'
                                required
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="flex gap-3 my-3">
                            <input
                                type="text"
                                name="subject"
                                placeholder='Subject'
                                className='p-2 bg-transparent col border-[1px] rounded-md border-black'
                                required
                                value={formData.subject}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="flex gap-3">
                            <textarea
                                type="text"
                                name="message"
                                rows={5}
                                placeholder='Type your message'
                                className='p-2 bg-transparent col border-[1px] rounded-md border-black'
                                required
                                value={formData.message}
                                onChange={handleChange}
                            />
                        </div>

                        <div className=" mt-3">
                            <button className='px-4 bg-pri font-thin text-white py-3 rounded-xl hover:shadow-lg'>Submit Now</button>
                        </div>
                    </form>
                </div>
            </Container>
        </div>
    );
}
