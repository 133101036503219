import React from "react";
import Container from "./Container";

export default function Message() {
   return (
      <div className="wrap py-16 bg-neutral-950">
         <Container>
            <div className="heading py-5">
               <div className="flex gap-2 justify-center">
                  <div className="wrap">
                     <hr className="my-1 w-[70px] text-red-500 border-2" />
                     <hr className="my-1 w-[50px] text-red-500 border-2" />
                     <hr className="my-1 w-[30px] text-red-500 border-2" />
                  </div>
                  <span className="text-xl font-bold text-red-500 uppercase">
                     Reach out to us
                  </span>
               </div>

               <div className="text-wrap text-center">
                  <span className="text-5xl font-bold text-white">
                     Contact us by sending a message
                  </span>
                  <div className="text-wrap text-md col-lg-6 mx-auto mt-3 font-thin text-white">
                     <span>
                        We're glad to hear from you anyday and anytime. Compliment, complains or proposals, We are at your service
                     </span>
                  </div>
               </div>
            </div>
            <div className="grid lg:grid-cols-2">
               <div className="img-wrap">
                  <img src="w-5.jpeg" alt="w-5" />
               </div>
               <div className="wrap bg-white shadow p-4 rounded">
                  <div className="heading text-2xl text-pri font-bold">
                     <span>We'd like to hear from you</span>
                  </div>

                  <div className="form-wrap">
                     <div className="form-group my-3">
                        <label className="text-sm font-thin mb-2">
                           {" "}
                           Email<span className="text-red-500">*</span>
                        </label>
                        <input
                           type="text"
                           placeholder="abc@example.com"
                           className="form-control placeholder:font-thin shadow-sm "
                        />
                     </div>

                     <div className="form-group my-3">
                        <label className="text-sm font-thin mb-2">
                           {" "}
                           Tel<span className="text-red-500">*</span>
                        </label>
                        <input
                           type="number"
                           placeholder="+012 3456 6789"
                           className="form-control placeholder:font-thin shadow-sm "
                        />
                     </div>
                     <div className="form-group my-3">
                        <label className="text-sm font-thin mb-2">
                           {" "}
                           Subject<span className="text-red-500">*</span>
                        </label>
                        <input
                           type="text"
                           placeholder="This is my subject"
                           className="form-control placeholder:font-thin shadow-md"
                        />
                     </div>
                     <div className="form-group my-3">
                        <label className="text-sm font-thin mb-2">
                           {" "}
                           Your message <span className="text-red-500">*</span>
                        </label>
                        <textarea
                           type="text"
                           rows={5}
                           placeholder="Type your message here"
                           className="form-control placeholder:font-thin shadow-md"
                        />
                     </div>
                     <div className="btn-wrap">
                        <button className="bg-red-500 w-full p-2 rounded-md text-white">
                           Send Message
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </Container>
      </div>
   );
}
