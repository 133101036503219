import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function FindTracking() {
   const navi = useNavigate();
   const [tracking_id, settracking_id] = useState("");
   return (
      <div className="find-tracking">
         <div className="container">
            <div className="col-wrap d-flex justify-content-center align-items-center">
               <div className="col-lg-6 col-md-6 mx-auto p-4 shadow">
                  <div className="head">
                     <span className="fs-3 fw-bold">Find Tracking</span>
                  </div>{" "}
                  <br />
                  <div className="row mb-3">
                     <div className="col-md-8">
                        <input
                           type="number"
                           placeholder="Tracking ID"
                           className="form-control mb-3"
                           value={tracking_id}
                           onChange={(e) => settracking_id(e.target.value)}
                           required
                        />
                     </div>
                     <div className="col-md-4">
                        <div className="btn-wrap">
                           <button
                              className="btn btn-danger d-flex align-items-center gap-2 fw-bold "
                              onClick={() =>
                                 navi(`/?tracking_id=${tracking_id}`)
                              }
                           >
                              <FaSearch /> Track
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
