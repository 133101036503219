import React, { useContext, useEffect, useState } from "react";
import { RouterContext } from "../../../utils/context/RouterContext";

export default function Sender() {
   const { state, dispatch } = useContext(RouterContext);

   // Input state
   const [sender_name, setSender_name] = useState("");
   const [sender_email, setSender_email] = useState("");
   const [sender_phoneNo, setSender_phoneNo] = useState("");
   const [sender_address, setSender_address] = useState("");

   // update the senderData when input changes
   useEffect(() => {
      updateSenderData();
   }, [sender_name, sender_email, sender_phoneNo, sender_address]);

   // updates the sender data in the reducer.
   const updateSenderData = () => {
      dispatch({
         type: "sender_details",
         payload: { sender_name, sender_email, sender_phoneNo, sender_address },
      });
   };
   return (
      <div className="sender">
         <div className="container">
            <div className=" col-lg-6 p-3 form-wrap shadow mx-auto">
               <div className="head">
                  <span className="fs-5 fw-bold">Senders Details</span>
               </div>
               <div className="body">
                  <div className="form-group">
                     <div className="row">
                        <div className="col-md-6">
                           <input
                              type="text"
                              placeholder="Sender's Name *"
                              className="form-control mb-3"
                              required
                              value={sender_name}
                              onChange={(e) => setSender_name(e.target.value)}
                           />
                        </div>
                        <div className="col-md-6">
                           <input
                              type="text"
                              placeholder="Sender's Address *"
                              className="form-control mb-3"
                              required
                              value={sender_address}
                              onChange={(e) =>
                                 setSender_address(e.target.value)
                              }
                           />
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-md-6">
                           <input
                              type="email"
                              placeholder="Sender's Email *"
                              className="form-control mb-3"
                              required
                              value={sender_email}
                              onChange={(e) => setSender_email(e.target.value)}
                           />
                        </div>
                        <div className="col-md-6">
                           <input
                              type="number"
                              placeholder="Sender's Phone No *"
                              className="form-control mb-3"
                              required
                              value={sender_phoneNo}
                              onChange={(e) =>
                                 setSender_phoneNo(e.target.value)
                              }
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
