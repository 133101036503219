import React from 'react'
import Abt from '../Components/Abt'
import Card from '../Components/Card'
import ChooseUs from '../Components/ChooseUs'
import ContactHaader from '../Components/ContactHaader'
import Footer from '../Components/Footer'
import Hero from '../Components/Hero'
import Message from '../Components/Message'
import Process from '../Components/Process'
import Testimony from '../Components/Testimony'

export default function Home() {
  return (
    <div className="wrap">
        {/* <ContactHaader /> */}
        <Hero />
        <Card />
        <Abt />
        <ChooseUs />
        <Process />
        <Message />
        <Testimony />
        <Footer />
    </div>
  )
}
