import React from "react";
import Container from "./Container";

export default function Process() {
   const dum = [1, 1, 1, 2];

   const imgFunc = (img, title, text) => {
      return { img, title, text }
   }
   const imgArr = [
      imgFunc('/h1.png', "Order Initiated", "Orders are instantly initiated once package is ready"),
      imgFunc('/h2.png', "Order Dispatched", "Package is then dipatched from logistic section to destination"),
      imgFunc('/h3.png', "Order Delivered", "Package is delivered securely to desired location by client"),
      imgFunc('/h4.png', "Order Payment", "Payment is made before package is moved from primary location"),
   ]
   return (
      <div className="wrap py-32">
         <Container>
            <div className="heading">
               <div className="flex gap-2 justify-center">
                  <div className="wrap">
                     <hr className="my-1 w-[70px] text-red-500 border-2" />
                     <hr className="my-1 w-[50px] text-red-500 border-2" />
                     <hr className="my-1 w-[30px] text-red-500 border-2" />
                  </div>
                  <span className="text-xl font-bold text-red-500 uppercase">
                     HOW IT WORK
                  </span>
               </div>

               <div className="text-wrap text-center">
                  <span className="text-5xl font-bold">
                     We follow great process
                  </span>
                  <div className="text-wrap text-md col-lg-6 mx-auto mt-3">
                     <span>
                     We adhere to a strategic and secure approach in the transportation of goods and services
                     </span>
                  </div>
               </div>

               <div className="grid lg:grid-cols-4 gap-2 my-5">
                  {imgArr.map((i, index) => (
                     <div className={`wrap text-center flex items-center ${index === 1 && "lg:translate-y-[50px]"} ${index === 3 && "lg:translate-y-[50px]"}`}>
                        <div className="wrap">
                           <div className="img-wrap relative flex justify-center items-center">
                              <img
                                 src={i.img}
                                 className="w-[70px] h-[70px] absolute mx-auto"
                              />
                              <img
                                 src="bgh.png"
                                 className="w-[200px] h-[200px]"
                              />
                           </div>
                           <div className="heading font-bold text-xl text-pri">
                              <span>{i.title}</span>
                           </div>
                           <div className="text-wrap text-sm col-10 mx-auto col-lg-12">
                              {i.text}
                           </div>
                        </div>
                        {index === 0 && (
                           <div className="arrow hidden lg:block">
                              <img
                                 src="/arrowOne.png"
                                 alt="Arrow"
                                 className="w-[200px]"
                              />
                           </div>
                        )}
                        {index === 2 && (
                           <div className="arrow hidden lg:block">
                              <img
                                 src="/arrowOne.png"
                                 alt="Arrow"
                                 className="w-[200px]"
                              />
                           </div>
                        )}

                        {index === 1 && (
                           <div className="arrow hidden lg:block">
                              <img
                                 src="/arrowTwo.png"
                                 alt="Arrow"
                                 className="w-[200px]"
                              />
                           </div>
                        )}
                        {index === 3 && (
                           <div className="arrow hidden lg:block">
                              <img
                                 src="/arrowTwo.png"
                                 alt="Arrow"
                                 className="w-[200px]"
                              />
                           </div>
                        )}
                     </div>
                  ))}
               </div>
            </div>
         </Container>
      </div>
   );
}
