import React from 'react'
import { FaEnvelope, FaLocationArrow } from 'react-icons/fa'
import Container from '../Container'

export default function ContactIntro() {
    return (
        <div className="wrap py-16">
            <Container>
                <div className="grid lg:grid-cols-2">
                    <div className="wrap">
                        <div className="heading text-4xl mb-2">
                            <span className='text-semibold'>Get In</span> <br /> <span className=' font-extrabold'>Touch </span>
                        </div>
                        <div className="text-wrap font-thin">
                            <span>Ready to streamline your logistics? Get in touch with Sperckz Logistics for personalized solutions tailored to your business needs!</span> <br /> <br />
                            <span>We'll be glad to hear from you.</span>
                        </div>

                        <div className="socials-con border-t-2 py-3 mt-4">
                            <div className="flex gap-3">
                                <div className="wrap bg-pri text-2xl text-white flex items-center justify-center p-3 rounded-lg">
                                    <span><FaLocationArrow /></span>
                                </div>
                                <div className="text-wrap">
                                    <div className="heading font-bold text-red-500">
                                        <span>Location</span>
                                    </div>
                                    <div className="text font-thin">
                                        <span>20 Eastbourne Terrace London W2 6LG</span>
                                    </div>
                                </div>
                            </div>

                            <div className="flex gap-3 my-3">
                                <div className="wrap bg-pri text-2xl text-white flex items-center justify-center p-3 rounded-lg">
                                    <span><FaEnvelope /></span>
                                </div>
                                <div className="text-wrap">
                                    <div className="heading font-bold text-red-500">
                                        <span>Mail address</span>
                                    </div>
                                    <div className="text font-thin">
                                        <span>sperckz@sperckz.com</span> <br />
                                        <span>support@sperckz.com</span>
                                        <span> </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="img-wrap flex justify-end">
                        <img src="/ct.png" alt="contact" />
                    </div>
                </div>
            </Container>
        </div>
    )
}
