import React from "react";
import Container from "../Container";

export default function AbtIntro() {
   return (
      <div className="wrap py-24">
         <Container>
            <div className="grid lg:grid-cols-2 items-center">
               <div className="img-wrap">
                  <div className="flex gap-3 items-center">
                     <div className="wrap">
                        <img
                           src="ab.png"
                           alt="Ship.png"
                           className="h-[200px] "
                        />{" "}
                        <br />
                        <img
                           src="call.png"
                           alt="Ship.png"
                           className="h-[200px] "
                        />
                     </div>
                     <div className="wrap">
                        <img
                           src="plane.jpeg"
                           alt="Ship.png"
                           className="w-[400px] rounded-xl"
                        />
                     </div>
                  </div>
               </div>
               <div className="wrap">
                  <div className="box">
                     <div className="heading">
                        <span className="text-red-500 font-bold">
                           About Company{" "}
                        </span>
                        <div className="text-wrap mt-2">
                           <span className="text-4xl font-extrabold">
                              Digital & trusted transport logistic company
                           </span>
                        </div>
                     </div>

                     <div className="text-wrap my-5">
                        <span>
                        Sperckz Logistics is a dynamic and innovative logistics company dedicated to providing comprehensive solutions for transporting goods efficiently and reliably. <br /> With our state-of-the-art facilities and cutting-edge technology, we specialize in managing the entire logistics process from warehousing to distribution, ensuring seamless delivery of goods to their destinations. Our team of experienced professionals is committed to delivering exceptional service, offering personalized solutions tailored to meet the unique needs of each client. <br /> At Sperckz Logistics, we pride ourselves on our commitment to excellence, cost-effectiveness, and round-the-clock availability, making us the preferred choice for businesses seeking reliable logistics partners.
                        </span>
                     </div>

                     <div className="icon-wrap flex flex-wrap gap-3 items-center">
                        <div className="wrap flex gap-3 items-center">
                           <div className="img-wrap bg-gray-300 p-3">
                              <img
                                 src="check.png"
                                 alt="check"
                                 className="w-[60px]"
                              />
                           </div>
                           <div className="text-wrap leading-6">
                              <span className="font-extrabold text-4xl">
                                 1959+
                              </span>{" "}
                              <br />
                              <span>Delivery Successful</span>
                           </div>
                        </div>
                        <div className="wrap flex gap-3 items-center">
                           <div className="img-wrap bg-gray-300 p-3">
                              <img
                                 src="check2.png"
                                 alt="check"
                                 className="w-[60px]"
                              />
                           </div>
                           <div className="text-wrap leading-6">
                              <span className="font-extrabold text-4xl">
                                 1959+
                              </span>{" "}
                              <br />
                              <span>Delivery Successful</span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </Container>
      </div>
   );
}
