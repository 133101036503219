import React from 'react'
import Breadcrumb from '../Components/Breadcrumb'
import ContactIntro from '../Components/contact/ContactIntro'
import LeaveMessage from '../Components/contact/LeaveMessage'
import ContactHaader from '../Components/ContactHaader'
import Footer from '../Components/Footer'
import StickyHeader from '../Components/Header'

export default function Contact() {
  return (
    <div className="wrap">
        <StickyHeader />
        <Breadcrumb title={'Contact Us'} />
        <ContactIntro />
        <LeaveMessage />
        <Footer />
    </div>
  )
}
