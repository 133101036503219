import React from 'react'
import { FaCog, FaFastForward, FaHeadset, FaSkullCrossbones, FaUserCog } from 'react-icons/fa'
import Container from '../Container'

export default function Special() {
    const cardFunc = (icon, title, text) => {
        return { icon, title, text }
     }
     const cardArr = [
        cardFunc(<FaCog />, "Trusted Franchise", "We are trusted by our partners, clients and customers"),
        cardFunc(<FaHeadset />, "Customer Support", "24/7 customer support. All agents are readily available."),
        cardFunc(<FaFastForward />, "Reliability & Punctuality", "We delivered all goods amd package items in time"),
     ]

    return (
        <div className="wrap py-16 bg-gray-100">
            <Container>
                <div className="heading text-center px-2">
                    <span className='text-red-500 font-bold'>Our Goodness</span>
                    <div className="text-wrap mt-2 text-4xl font-extrabold">
                        <span>What Make Us Special</span>
                    </div>
                </div>

                <div className="grid gap-3 lg:grid-cols-3 mt-5">
                    {cardArr.map((i, index) => (
                        <div className="card py-5 px-3 rounded-3xl hover:shadow-xl trans" key={index}>
                            <div className="icon-wrap flex justify-center items-center">
                                <span className='flex p-3 bg-red-300 rounded-full text-3xl'>{i.icon}</span>
                            </div>
                            <div className="text-wrap text-center mt-4">
                                <div className="heading font-bold text-xl">
                                    <span>{i.title}</span>
                                </div>
                                <div className="text text-gray-500 mt-2">
                                    <span>{i.text}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
        </div>
    )
}
